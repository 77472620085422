import { defineComponent, ref } from 'vue';
import { translateAchievementType } from '@/assets/ts/helpers/achievement';
export default defineComponent({
    emits: [
        'cancel-action',
        'execute-action'
    ],
    setup(props, context) {
        const changedAchievements = ref([]);
        const toggler = ref(null);
        function setChangedAchievements(newAchievementsStatuses) {
            changedAchievements.value = newAchievementsStatuses;
        }
        function execute() {
            toggleModal();
            const events = {
                achievements: changedAchievements.value,
                toggler: toggler
            };
            context.emit('execute-action', events);
        }
        function toggleModal() {
            const toggleModal = document.querySelector('#modalToggler');
            if (toggleModal) {
                toggleModal.click();
            }
        }
        function cancel() {
            context.emit('cancel-action');
        }
        function getAchievementTypeName(typeName) {
            return translateAchievementType(typeName);
        }
        return { cancel, changedAchievements, execute, getAchievementTypeName, toggler, toggleModal, setChangedAchievements };
    }
});
