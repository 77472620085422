import { defineComponent, ref } from 'vue';
import { translateAchievementType } from '@/assets/ts/helpers/achievement';
import { copyToClipboard } from '@/assets/ts/helpers/clipboard';
import { getObjectValues } from '@/assets/ts/helpers/object-entries';
import Modal from '@/components/layout/modal/Modal.vue';
export default defineComponent({
    components: {
        Modal
    },
    props: {
        awards: {
            type: Object,
            required: false,
            default: []
        },
        profileId: {
            type: String,
            required: true
        }
    },
    emits: ['on-confirm'],
    setup(props, context) {
        const successModalRef = ref();
        const summaryRef = ref(null);
        const toggleModal = () => successModalRef.value.toggleModal();
        const getAchievementLabel = (achievementName) => translateAchievementType(achievementName);
        const emitChanges = () => context.emit('on-confirm');
        const prepareBBCode = () => {
            if (!props.awards || !props.awards.details || !props.awards.sum) {
                return '';
            }
            let output = '[b]Podsumowanie:[/b]\n';
            getObjectValues(props.awards.details).forEach((awardsRow) => {
                output += `Osiągnięcia ${getAchievementLabel(awardsRow.label)}: `;
                output += '[b]';
                output += `${awardsRow.count}/X, `;
                output += `${awardsRow.gold}m + ${awardsRow.currency} kw.`;
                output += '[/b]';
                output += '\n';
            });
            output += '\n';
            output += '[b]';
            output += 'Łącznie: ';
            output += `${props.awards.sum.gold}m + ${props.awards.sum.currency} kw.`;
            output += '[/b]';
            return output;
        };
        const copySummaryToClipboard = ($event) => {
            $event.stopPropagation();
            copyToClipboard(prepareBBCode());
        };
        return { emitChanges, getAchievementLabel, copySummaryToClipboard, toggleModal, successModalRef, summaryRef };
    }
});
