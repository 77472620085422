import { defineComponent, ref } from 'vue';
import { getAdminFilteredAchievements } from '@/assets/ts/api/achievement';
import PanelAchievementList from '@/components/panel/panel-achievement-list/PanelAchievementList.vue';
import TextFilter from '@/components/core/filters/text-filter/TextFilter.vue';
export default defineComponent({
    components: {
        PanelAchievementList,
        TextFilter
    },
    setup() {
        const profileIdInput = ref();
        const enteredProfileIdInput = ref('');
        const achievements = ref([]);
        const achievementsBefore = ref([]);
        function onChangedStatuses() {
            loadAchievements();
        }
        function loadAchievements() {
            achievements.value = [];
            achievementsBefore.value = [];
            enteredProfileIdInput.value = '';
            const profileId = profileIdInput.value.getInputValue();
            const filterData = {
                profile_id: profileId
            };
            getAdminFilteredAchievements(filterData).then((results) => {
                enteredProfileIdInput.value = profileId;
                achievementsBefore.value = results.achievements;
                achievements.value = results.achievements;
            }).catch((err) => {
                console.log(`getFilteredAchievements(${filterData}) err`, err);
            });
        }
        return {
            achievements,
            achievementsBefore,
            profileIdInput,
            enteredProfileIdInput,
            loadAchievements,
            onChangedStatuses,
        };
    }
});
