import { defineComponent, ref } from 'vue';
import FormToggleSwitch from '@/components/layout/form/toggle-switch/FormToggleSwitch.vue';
import { translateAchievementType } from '@/assets/ts/helpers/achievement';
export default defineComponent({
    components: {
        FormToggleSwitch
    },
    props: {
        profileId: {
            type: String,
            required: false,
            default: ''
        },
        achievement: {
            type: Object,
            required: true
        }
    },
    emits: [
        'change-achievement-status'
    ],
    setup(props, context) {
        const toggler = ref();
        function changeAchievementStatus(achievement) {
            const event = {
                achievement: achievement,
                toggler: toggler
            };
            context.emit('change-achievement-status', event);
        }
        function getAchievementClasses(type) {
            return {
                'achievement-starting': !!type && type.name === 'STARTING',
                'achievement-main': !!type && type.name === 'MAIN',
                'achievement-normal': !!type && type.name === 'NORMAL',
                'achievement-hard': !!type && type.name === 'HARD',
                'achievement-laborious': !!type && type.name === 'LABORIOUS'
            };
        }
        function getTitle(title) {
            return translateAchievementType(title);
        }
        return {
            toggler,
            getAchievementClasses,
            changeAchievementStatus,
            getTitle
        };
    }
});
