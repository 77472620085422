import { defineComponent, ref, watch } from 'vue';
import { changeAchievementsStates } from '@/assets/ts/api/achievement';
import { getLoggedAdminId } from '@/assets/ts/helpers/admin';
import PanelAchievementStatus from '@/components/panel/panel-achievement-list/panel-achievement-status/PanelAchievementStatus.vue';
import AchievementStateConfirmation from '@/components/panel/panel-achievement-list/AchievementStateConfirmation.vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
import SuccessModal from '@/components/panel/panel-achievement-list/SuccessModal.vue';
export default defineComponent({
    components: {
        SuccessModal,
        AchievementStateConfirmation,
        CTAButton,
        PanelAchievementStatus
    },
    props: {
        achievementList: {
            type: Array,
            required: false,
            default: []
        },
        profileId: {
            type: String,
            required: false,
            default: ''
        },
    },
    emits: [
        'changed-statuses'
    ],
    setup(props, context) {
        const awards = ref(null);
        const successModalRef = ref(null);
        const achievementActionModal = ref();
        const achievementListBefore = ref([]);
        watch(() => props.achievementList, (newState) => {
            achievementListBefore.value = JSON.parse(JSON.stringify(newState));
        });
        // TODO:
        // eliminate duplicate and split into pure function or helper or sth
        function getAchievementDivClasses(type) {
            return {
                'achievement-starting': !!type && type.name === 'STARTING',
                'achievement-main': !!type && type.name === 'MAIN',
                'achievement-normal': !!type && type.name === 'NORMAL',
                'achievement-hard': !!type && type.name === 'HARD',
                'achievement-laborious': !!type && type.name === 'LABORIOUS'
            };
        }
        function changeAchievementStatusModal() {
            const changed = [];
            props.achievementList.forEach((achievement) => {
                const oldState = achievementListBefore.value.find((achievementOld) => {
                    return achievementOld.achievement_id === achievement.achievement_id;
                });
                if (oldState && oldState.done !== achievement.done) {
                    changed.push(achievement);
                }
            });
            achievementActionModal.value.setChangedAchievements(changed);
            achievementActionModal.value.toggleModal();
        }
        function cancelAction() {
            // console.log('cancelAction');
        }
        function executeAction(event) {
            const achievementData = {
                profile_id: props.profileId,
                achievements: event.achievements,
                admin_id: getLoggedAdminId()
            };
            changeAchievementsStates(achievementData).then((results) => {
                awards.value = results === null || results === void 0 ? void 0 : results.awards;
                successModalRef.value.toggleModal();
            }).catch((err) => {
                console.log('changeAchievementsStates() err', achievementData, err);
                cancelAction();
            });
        }
        function changedAchievementValue(event) {
            const changedAchievementId = event.achievement.achievement_id;
            const changedAchievementValue = event.toggler.value.getValue();
            const found = props.achievementList.filter((achievement) => achievement.achievement_id === changedAchievementId);
            if ((found === null || found === void 0 ? void 0 : found.length) > 0) {
                found[0].done = changedAchievementValue;
            }
            else {
                console.error('failed to change achievement state');
            }
        }
        const onChangesConfirm = () => context.emit('changed-statuses');
        return {
            achievementActionModal,
            awards,
            successModalRef,
            cancelAction,
            changeAchievementStatusModal,
            changedAchievementValue,
            executeAction,
            getAchievementDivClasses,
            onChangesConfirm
        };
    }
});
